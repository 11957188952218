<template>
  <div>
    <create
      @closePopup="createDialog = false"
      @createData="create($event)"
      @getCountries="getCountryData($event)"
      :countryItems="countryItems"
      :dialog="createDialog"
      :loader="createLoader"
    />
    <edit
      @closePopup="editDialog = false"
      @editData="edit($event)"
      @getCountries="getCountryData($event)"
      :countryItems="countryItems"
      :dialog="editDialog"
      :dialogData="showData"
      :loader="showLoader"
    />
    <delete-popup
      @closePopup="deleteDialog = false"
      @deleteData="deleteItem($event)"
      :dialog="deleteDialog"
      :item="deleteDialogData"
      :loader="deleteLoader"
    />
    <v-dialog
      v-if="bulkLoader"
      v-model="uploadDialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t("uploading") }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row class="my-1">
      <v-col lg="6">
        <students-filter
          @getData="setDataFilter($event)"
          @clearData="clearData"
        />
      </v-col>
    </v-row>
    <v-row class="my-1">
      <v-col lg="12">
        <v-btn
          @click="createAble"
          x-large
          class=" pa-2 ma-1 rounded-xl"
          color="primary"
        >
          {{ $t("add new student") }}<v-icon class="ma-2">mdi-plus</v-icon>
        </v-btn>
        <v-btn
          @click="openFilePicker"
          x-large
          class=" pa-2 ma-1 rounded-xl"
          color="primary"
        >
          {{ $t("add students from file") }}
          <v-icon class="ma-2">mdi-plus</v-icon>
        </v-btn>

      <v-btn
          @click="downloadStudentsTemplate"
          x-large
          class=" pa-2  ma-1  rounded-xl"
          color="primary"
        >
          {{ $t("download students template")
          }}<v-icon class="ma-2">mdi-download</v-icon>
        </v-btn>
      <v-btn
          @click="downloadStudent"
          x-large
          class=" pa-2 ma-1 rounded-xl"
          color="primary"
        >
          {{ $t("Upload Student Information") }}
          <v-icon class="ma-1">mdi-download</v-icon>
        </v-btn>
      <!-- btn -->
      <v-col class="d-flex justify-end" lg="6" >
      </v-col>
        </v-col>
    </v-row>
    <data-table
      @updatePage="getData($event)"
      :headers="tableData.headers"
      :data="tableData.data"
      :editable="editAble"
      :deleteable=" deleteAble"
      :loader="loader"
      :meta="pagination"
      :isLocalized="false"
    />
  </div>
</template>
<script>
// for get exel file
import ExcelJS from "exceljs";

// mobx
import { Users } from "@/store/common/users/student";
import { Country } from "@/store/common/country";
import { Csv } from "@/store/common/csv";

//base component
import dataTable from "@/components/mycomponent/base/dataTable.vue";
import studentsFilter from "./filter.vue";
import create from "./create.vue";
import edit from "./edit.vue";
import deletePopup from "@/pages/Dashboard/age-group/delete.vue";
export default {
  props: ["id", "enableEdit"],
  components: {
    dataTable,
    studentsFilter,
    create,
    edit,
    deletePopup,
  },
  data() {
    return {
      //data
      Users,
      Country,
      Csv,

      //dialogs
      createDialog: false,
      editDialog: false,
      deleteDialog: false,
      uploadDialog: true,

      dialogData: null,
      deleteDialogData: null,
      countryItems: [],
      filterData: {},
    };
  },
  methods: {
    //CSV methods
    async downloadStudentsTemplate() {
      await Csv.get("student");
    },
    downloadStudent() {
      Users.getPdf(this.id);
    },
    openFilePicker() {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = ".csv, .xlsx, .xls";
      input.addEventListener("change", this.readFile);
      input.click();
    },
    async readFile(event) {
      const file = event.target.files[0];
      const fileExtension = file.name.split(".").pop();

      if (fileExtension === "csv") {
        const text = await file.text();
        const rows = text.split("\n");
        const headerRow = rows[0].split(",");
        const dataRows = rows.slice(1, -1);
        const jsonData = dataRows.map((row) => {
          const values = row.split(",");
          const obj = {};
          headerRow.forEach((header, index) => {
            obj[header] = values[index];
          });
          return obj;
        });
        await Users.bulkPost({ students: jsonData });
        this.getData({ page: 1, class_id: this.id });
      } else if (["xls", "xlsx"].includes(fileExtension)) {
        const workbook = new ExcelJS.Workbook();
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = async () => {
          const buffer = reader.result;
          const data = new Uint8Array(buffer);
          await workbook.xlsx.load(data);
          const worksheet = workbook.getWorksheet(1);
          const headerRow = worksheet.getRow(1).values;
          const jsonData = [];
          worksheet.eachRow((row, rowNumber) => {
            if (rowNumber > 1) {
              const rowData = row.values;
              const obj = {};
              headerRow.forEach((header, index) => {
                obj[header] = rowData[index];
              });
              Object.assign(obj, { edu_class_id: this.id });
              jsonData.push(obj);
            }
          });
          await Users.bulkPost({ students: jsonData });
          this.getData({ page: 1, class_id: this.id });
        };
      } else {
      }
    },

    //get methods
    getData(page) {
      Object.assign(this.filterData, { page: page });
      Object.assign(this.filterData, { edu_class_id: this.id });
      Users.get(this.filterData);
    },
    async getCountryData(params) {
      if (Country.getState.loading) return;
      await Country.get(params);
      this.countryItems = this.countryItems.concat(this.country);
    },

    //filter methods
    setDataFilter(data) {
      this.filterData = data;
      this.getData(1);
    },
    clearData() {
      this.filterData = {};
      this.getData({ page: 1, edu_class_id: this.id });
    },

    //create
    createAble() {
      this.createDialog = true;
    },
    async create(data) {
      Object.assign(data, { edu_class_id: this.id });
      await Users.post(data);
      this.createDialog = false;
      this.getData({ page: 1, edu_class_id: this.id });
    },

    //edit
    async editAble(obj) {
      this.editDialog = true;
      await Users.show(obj.id);
    },
    async edit(data) {
      Object.assign(data.data, { edu_class_id: this.id });

      await Users.edit(data.data, data.id);
      this.getData({ page: 1, edu_class_id: this.id });
      this.editDialog = false;
    },

    //delete
    deleteAble(obj) {
      this.deleteDialog = true;
      this.deleteDialogData = obj;
    },
    async deleteItem(id) {
      await Users.deleteItem(id);
      this.deleteDialog = false;
      this.getData({ page: 1, edu_class_id: this.id });
    },
    closePopup() {
      this.dialog = false;
    },
  },
  computed: {
    //data
    tableData() {
      return Users.tableData;
    },
    showData() {
      return Users.tableData.showData;
    },
    country() {
      return Country.tableData.data;
    },

    //loaders
    loader() {
      return Users.getState.loading;
    },
    createLoader() {
      return Users.postState.loading;
    },
    bulkLoader() {
      return Users.postBulkState.loading;
    },
    editLoader() {
      return Users.editState.loading;
    },
    showLoader() {
      return Users.showState.loading;
    },
    deleteLoader() {
      return Users.deleteState.loading;
    },

    //
    pagination() {
      return Users.tableData.meta;
    },
  },
  created() {
    this.getData({ page: 1, edu_class_id: this.id });
    this.getCountryData({ page: 1 });
  },
};
</script>